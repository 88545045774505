<script setup lang="ts">
const config = useLocalSite();
useHead({
  titleTemplate: (title) => {
    return title
      ? `${title} - ファミリー庭園${config.shop_name}`
      : `ファミリー庭園${config.shop_name}`;
  },
});
</script>

<template>
  <NuxtLoadingIndicator color="#01561f" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
